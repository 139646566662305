import {
    Select,
    Table,
    Input,
    Space,
    Modal,
    Form,
    InputNumber,
    notification,
    Progress,
    Card,
    Tag,
    Radio,
    Button,
    Popover,
  } from "antd";
  import {
    ExclamationCircleOutlined,
    LoadingOutlined,
    PlusOutlined,
    CheckCircleFilled,
    CloseCircleOutlined,
  } from "@ant-design/icons";
  import { useContext, useEffect, useState } from "react";
  import * as tus from "tus-js-client";
  import moment from "moment";
  
  import { Context } from "../../context";
  import { Fetch, Post, Put, Delete } from "../../fetch";
  import { convertIdToKey } from "../../utils";
  import UploadBanners from "../UploadBanners/index";
  
  const { Option } = Select;
  const { Column } = Table;
  const { confirm } = Modal;
  
  const ManageSongs = () => {
    const { apiUrl, token, transcodeUrl, superAdmin } = useContext(Context);
  
    const [songs, setSongs] = useState([]);
    const [songsMaster, setSongsMaster] = useState([]);
    const [song, setSong] = useState();
    const [actors, setActors] = useState([]);
    const [modalVisible, setModalVisible] = useState(false);
    const [confirmLoading, setConfirmLoading] = useState(false);
    const [uploading, setUploading] = useState(false);
    const [uploadProgress, setUploadProgress] = useState(0);
    const [contentExists, setContentExists] = useState(false);
    const [cardImageLoading, setCardImageLoading] = useState(false);
    const [counter, setCounter] = useState(0);
    const [directors, setDirectors] = useState([]);
    const [producers, setProducers] = useState([]);
    const [singers, setSingers] = useState([]);
    const [lyricists, setLyricists] = useState([]);
    const [genres, setGenres] = useState([]);
    const [languages, setLanguages] = useState([]);
  
    const [form] = Form.useForm();
  
    useEffect(() => {
      Fetch(`${apiUrl}/songs`, token, (result) => {
        const songs = convertIdToKey(result.songs);
        songs.forEach((s) => {
          if (!s.videoUploadDateTime) {
            s.videoUploadDateTime = "NA";
          } else {
            s.videoUploadDateTime = moment(s.videoUploadDateTime).format(
              "DD/MM/YYYY hh:mm A"
            );
          }
        });
        setSongs(songs);
        setSongsMaster(songs);
      });
  
      Fetch(`${apiUrl}/artists`, token, (result) => {
        setActors(result.artists);
      });
  
      Fetch(`${apiUrl}/genres`, token, (result) => {
        setGenres(result.genres);
      });
  
      Fetch(`${apiUrl}/languages`, token, (result) => {
        setLanguages(result.languages);
      });
  
      Fetch(`${apiUrl}/mediaHouses`, token, (result) => {
        setDirectors(result.directors);
        setProducers(result.producers);
        setSingers(result.singers);
        setLyricists(result.lyricists);
      });
    }, [apiUrl, token, counter]);
  
    const showEditModal = (record) => {
      setSong(record);
      setContentExists(record.playbackUrl ? true : false);
      setModalVisible(true);
      form.setFieldsValue(record);
    };
  
    const handleSubmit = async () => {
      setConfirmLoading(true);
      try {
        const values = await form.validateFields();
        Put(
          `${apiUrl}/songs/${song.key}`,
          token,
          {
            ...values,
          },
          (result) => {
            if (result.success) {
              form.resetFields();
  
              notification.success({
                message: "Success",
                description: "Song edited",
              });
              setModalVisible(false);
              setCounter(counter + 1);
            }
          },
          () => {
            notification.error({
              message: "Error",
              description: "Please check console for more details",
            });
          }
        );
      } catch (err) {
        console.error(err);
        notification.error({
          message: "Error",
          description: "Required fields incorrect/missing",
        });
      } finally {
        setConfirmLoading(false);
      }
    };
  
    const handleUploadChange = (e) => {
      const file = e.target.files[0];
  
      setUploading(true);
  
      const upload = new tus.Upload(file, {
        endpoint: `${transcodeUrl}/files`,
        retryDelays: [0, 3000, 5000, 10000, 20000],
        metadata: {
          filename: file.name,
          filetype: file.type,
        },
        onError: (error) => {
          console.log("Failed because: " + error);
        },
        onProgress: (bytesUploaded, bytesTotal) => {
          var percentage = ((bytesUploaded / bytesTotal) * 100).toFixed(2);
          setUploadProgress(percentage);
          console.log(bytesUploaded, bytesTotal, percentage + "%");
        },
        onSuccess: () => {
          Post(
            `${transcodeUrl}/submitJob`,
            token,
            {
              id: song.key,
              loc: upload.url.split("/").slice(-1)[0],
              media_type: "songs",
            },
            (result) => {
              console.log(result);
            },
            () => {
              console.error(`Error transcoding content`);
            }
          );
          console.log("Download %s from %s", upload.file.name, upload.url);
        },
      });
  
      upload.start();
    };
  
    const confirmDelete = (record) => {
      confirm({
        title: "Are you sure you want to delete this song?",
        icon: <ExclamationCircleOutlined />,
        okText: "Yes",
        okType: "danger",
        cancelText: "No",
        onOk() {
          Delete(
            `${apiUrl}/songs/${record.key}`,
            token,
            (result) => {
              if (result.success) {
                notification.success({
                  message: "Succes",
                  description: "Song deleted successfully",
                });
                setCounter(counter + 1);
              }
            },
            () => {
              notification.error({
                message: "Error deleting song",
                description: "Please check console for more details",
              });
            }
          );
        },
      });
    };
  
    const handleSearchChange = (songId) => {
      Fetch(`${apiUrl}/songs/${songId}`, token, (result) => {
        const song = convertIdToKey([result]);
        song.forEach((song) => {
          if (!song.videoUploadDateTime) {
            song.videoUploadDateTime = "NA";
          } else {
            song.videoUploadDateTime = moment(song.videoUploadDateTime).format(
              "DD/MM/YYYY hh:mm A"
            );
          }
        });
        setSongs(song);
      });
    };
  
    return (
      <div className="manage-episodes-wrapper">
        <Select
          placeholder="Search Song"
          showSearch
          allowClear
          onChange={handleSearchChange}
          onClear={() => setSongs(songsMaster)}
          optionFilterProp="children"
          filterOption={(input, option) =>
            option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
          }
          filterSort={(optionA, optionB) =>
            optionA.children
              .toLowerCase()
              .localeCompare(optionB.children.toLowerCase())
          }
        >
          {songsMaster.map((m) => (
            <Option value={m.key}>{m.title}</Option>
          ))}
        </Select>
        <Table dataSource={songs}>
          <Column title="Title" dataIndex="title" key="name" align="center" />
          <Column
            title="Language"
            dataIndex="language"
            key="language"
            align="center"
          />
          <Column
            title="Genres"
            dataIndex="genre"
            key="genre"
            align="center"
            render={(values) => (
              <>
                {values.map((v) => (
                  <Tag color="blue">{v}</Tag>
                ))}
              </>
            )}
          />
          <Column title="Model" dataIndex="model" key="model" align="center" />
          <Column
            title="Availability"
            dataIndex="availability"
            key="availability"
            align="center"
          />
          <Column
            title="Content Exists"
            key="trailer"
            align="center"
            render={(_, record) =>
              record.playbackUrl ? <CheckCircleFilled /> : <CloseCircleOutlined />
            }
          />
          <Column
            title="Video Uploaded Date"
            dataIndex="videoUploadDateTime"
            key="videoUploadDateTime"
            align="center"
          />
          <Column
            title="Actions"
            key="action"
            align="center"
            render={(text, record) => {
              if (superAdmin || record.availability === "unpublished") {
                if (record.comments) {
                  return (
                    <Space size="middle">
                      <Popover
                        content={record.comments}
                        title="Admin Comments"
                        trigger="click"
                      >
                        <Button>View Comments</Button>
                      </Popover>
                      <Button onClick={() => showEditModal(record)}>Edit</Button>
                      <Button
                        onClick={() => {
                          confirmDelete(record);
                        }}
                      >
                        Delete
                      </Button>
                    </Space>
                  );
                }
                return (
                  <Space size="middle">
                    <Button onClick={() => showEditModal(record)}>Edit</Button>
                    <Button
                      onClick={() => {
                        confirmDelete(record);
                      }}
                    >
                      Delete
                    </Button>
                  </Space>
                );
              } else {
                return "-";
              }
            }}
          />
        </Table>
        <Modal
          title="Edit Song"
          visible={modalVisible}
          onOk={handleSubmit}
          okText="Update"
          onCancel={() => setModalVisible(false)}
          confirmLoading={confirmLoading}
          maskClosable={false}
        >
          <Form form={form} layout="vertical">
            <Form.Item name="title" label="Title" rules={[{ required: true }]}>
              <Input size="middle" />
            </Form.Item>
            <Form.Item
              name="language"
              label="Language"
              rules={[{ required: true }]}
            >
              <Select placeholder="Choose a language" showSearch>
                {languages.map((language) => (
                  <Option value={language.name}>{language.name}</Option>
                ))}
              </Select>
            </Form.Item>
            <Form.Item
              name="genre"
              label="Genre / Category"
              rules={[{ required: true }]}
            >
              <Select placeholder="Choose a genre" mode="multiple" showSearch>
                {genres.map((genre) => (
                  <Option value={genre.name}>{genre.name}</Option>
                ))}
              </Select>
            </Form.Item>
            <Form.Item
              name="maturity"
              label="Maturity"
              rules={[{ required: true }]}
            >
              <Select placeholder="Choose maturity">
                <Option value="U">U (Universal)</Option>
                <Option value="U/A 7+">U/A 7+ (For Ages 7 and Above)</Option>
                <Option value="U/A 13+">U/A 13+ (For Ages 13 and Above)</Option>
                <Option value="U/A 16+">U/A 16+ (For Ages 16 and Above)</Option>
                <Option value="A">A (Adults - Ages 18 and Above)</Option>
              </Select>
            </Form.Item>
            <Form.Item name="year" label="Year" rules={[{ required: true }]}>
              <Input />
            </Form.Item>
            <Card title="Cast &amp; Crew" className="form-card">
              <Form.Item
                name="actors"
                label="Actors"
                className="form-item-selector"
                rules={[{ required: true }]}
              >
                <Select showSearch mode="multiple" className="form-card-selector">
                  {actors.map((actor) => (
                    <Option key={actor.key} value={actor.name}>
                      {actor.name}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
              <Form.Item
                name="singers"
                label="Singers"
                className="form-item-selector"
                rules={[{ required: true }]}
              >
                <Select showSearch mode="multiple">
                  {singers.map((singer) => (
                    <Option key={singer.key} value={singer.name}>
                      {singer.name}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
              <Form.Item
                name="lyricists"
                label="Lyricists"
                className="form-item-selector"
                rules={[{ required: true }]}
              >
                <Select showSearch mode="multiple">
                  {lyricists.map((lyricist) => (
                    <Option key={lyricist.key} value={lyricist.name}>
                      {lyricist.name}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
              <Form.Item
                name="directors"
                label="Directors"
                className="form-item-selector"
              >
                <Select showSearch mode="multiple" className="form-card-selector">
                  {directors.map((director) => (
                    <Option key={director.key} value={director.name}>
                      {director.name}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
              <Form.Item
                name="producers"
                label="Producers"
                className="form-item-selector"
              >
                <Select showSearch mode="multiple">
                  {producers.map((producer) => (
                    <Option key={producer.key} value={producer.name}>
                      {producer.name}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Card>
            <Form.Item
              name="cardImage"
              label="Card Image"
              rules={[{ required: true }]}
            >
              <UploadBanners
                name={"cardImageSong"}
                type="song"
                updateForm={form}
              />
            </Form.Item>
            <Form.Item
              name="duration"
              label="Duration"
              className="form-item-selector"
              rules={[{ required: true }]}
            >
              <InputNumber placeholder="Enter duration in seconds" />
            </Form.Item>
            <Form.Item label="Model" name="model">
              <Radio.Group>
                <Radio value="subscription">Subscription</Radio>
                <Radio value="free">Free</Radio>
              </Radio.Group>
            </Form.Item>
            {superAdmin && (
              <Form.Item
                name="availability"
                label="Availability"
                rules={[{ required: true }]}
              >
                <Radio.Group>
                  <Radio value="perpetual">Perpetual</Radio>
                  <Radio value="unpublished">Unpublished</Radio>
                </Radio.Group>
              </Form.Item>
            )}
            {superAdmin && (
              <Form.Item name="comments" label="Comments">
                <Input />
              </Form.Item>
            )}
          </Form>
        </Modal>
      </div>
    );
  };
  
  export default ManageSongs;
  