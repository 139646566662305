import {
  Form,
  Input,
  InputNumber,
  Modal,
  Select,
  Space,
  Table,
  Tag,
  Radio,
  DatePicker,
  notification,
  Progress,
  Button,
  Popover
} from "antd";
import { useContext, useEffect, useState } from "react";
import {
  PlusCircleTwoTone,
  ExclamationCircleOutlined,
  CloudDownloadOutlined,
  CheckCircleFilled,
  CloseCircleOutlined,
  EditOutlined,
  DeleteOutlined,
  CommentOutlined,
} from "@ant-design/icons";
import * as tus from "tus-js-client";
import moment from "moment";

import "./styles.css";

import { Context } from "../../context";
import { Fetch, Post, Delete, Put } from "../../fetch";
import { convertIdToKey, handleExportClick } from "../../utils";
import TextArea from "antd/lib/input/TextArea";
import UploadBanners from "../UploadBanners/index";
import MultiCurrencyInput from "../MultiCurrencyInput/index";

const { Column } = Table;
const { Option } = Select;
const { confirm } = Modal;

const ManageSeries = () => {
  const { token, apiUrl, transcodeUrl } = useContext(Context);

  const [series, setSeries] = useState([]);
  const [genres, setGenres] = useState([]);
  const [languages, setLanguages] = useState([]);
  const [actors, setActors] = useState([]);
  const [directors, setDirectors] = useState([]);
  const [producers, setProducers] = useState([]);
  const [availability, setAvailability] = useState("perpetual");
  const [modalVisible, setModalVisible] = useState(false);
  const [confirmLoading, setConfirmLoading] = useState(false);
  const [modalType, setModalType] = useState("");
  const [seriesId, setSeriesId] = useState();
  const [counter, setCounter] = useState(0);
  const [trailerUploading, setTrailerUploading] = useState(false);
  const [trailerUploadProgress, setTrailerUploadProgress] = useState(0);
  const [trailer, setTrailer] = useState();
  const [seriesMaster, setSeriesMaster] = useState([]);
  const [monetizationModel, setMonetizationModel] = useState();
  const [subscriberPrice, setSubscriberPrice] = useState();
  const [nonSubscriberPrice, setNonSubscriberPrice] = useState()
  const [contentExists, setContentExists] = useState()
  const [trailerExists, setTrailerExists] = useState()

  const [form] = Form.useForm();
  useEffect(() => {
    Fetch(`${apiUrl}/series?admin=${true}`, token, (result) => {
      const series = convertIdToKey(result.series);
      console.log("series_cms", series);
      series.forEach((su) => {
        if (!su.videoUploadDateTime) {
          su.videoUploadDateTime = "NA";
        } else {
          su.videoUploadDateTime = moment(su.videoUploadDateTime).format(
            "DD/MM/YYYY hh:mm A"
          );
        }
      });

      setSeries(series);
      setSeriesMaster(series);
    });

    Fetch(`${apiUrl}/genres`, token, (result) => {
      setGenres(result.genres);
    });

    Fetch(`${apiUrl}/languages`, token, (result) => {
      setLanguages(result.languages);
    });

    Fetch(`${apiUrl}/artists`, token, (result) => {
      setActors(result.artists);
    });

    Fetch(`${apiUrl}/mediaHouses`, token, (result) => {
      setDirectors(result.directors);
      setProducers(result.producers);
    });
  }, [apiUrl, token, counter]);

  useEffect(() => {
    if (!seriesId) {
      return;
    }

    Fetch(`${apiUrl}/series/${seriesId}`, token, (result) => {
      const { startDate, endDate, videoUploadDateTime, ...others } = result;
      if (startDate && endDate) {
        form.setFieldsValue({
          ...others,
          startDate: moment(startDate),
          endDate: moment(endDate),
        });
      } else if (startDate) {
        form.setFieldsValue({ ...others, startDate: moment(startDate) });
      } else if (videoUploadDateTime) {
        form.setFieldsValue({
          ...others,
          videoUploadDateTime: moment(videoUploadDateTime),
        });
      } else {
        form.setFieldsValue(others);
      }
      if (result.subscriberPrice) setSubscriberPrice(result.subscriberPrice);
      if (result.nonSubscriberPrice)
        setNonSubscriberPrice(result.nonSubscriberPrice);
      setAvailability(result.availability);
      setMonetizationModel(result.model);
      setContentExists(result.playbackUrl ? true : false);
      setTrailerExists(result.trailer ? true : false);
    });
  }, [apiUrl, token, counter, seriesId]);

  const handleSubmit = async () => {
    if (modalType === "Edit") {
      setConfirmLoading(true);
      try {
        let fields = await form.validateFields();
        if (fields.startDate)
          fields["startDate"] = fields.startDate.toISOString(true);
        if (fields.endDate)
          fields["endDate"] = fields.endDate.toISOString(true);
        Put(
          `${apiUrl}/series/${seriesId}`,
          token,
          { ...fields, trailer },
          (result) => {
            if (result.success) {
              notification.success({
                message: "Success",
                description: "Series updated",
              });
            }
            form.resetFields();
            setModalVisible(false);
            setConfirmLoading(false);
            setCounter(counter + 1);
          },
          () => {
            notification.error({
              message: "Edit Series Error",
              description: "Check console for more details",
            });
          }
        );
      } catch (err) {
        notification.error({
          message: "Edit Series Error",
          description: "Required fields are incorrect/missing",
        });
      }
      return;
    }

    try {
      setConfirmLoading(true);
      const fields = await form.validateFields();
      Post(
        `${apiUrl}/series`,
        token,
        { ...fields, trailer },
        (result) => {
          if (result.success) {
            notification.success({
              message: "Success",
              description: "Series created",
            });
          }
          form.resetFields();
          setModalVisible(false);
          setCounter(counter + 1);
        },
        () => {
          notification.error({
            message: "Add Series Error",
            description: "Check console for more details",
          });
        }
      );
    } catch (err) {
      console.error(err);
      notification.error({
        message: "Add Series Error",
        description: "Required fields are incorrect/missing",
      });
    } finally {
      setConfirmLoading(false);
    }
  };

  const showEditModal = (id) => {
    setModalType("Edit");
    setModalVisible(true);
    Fetch(`${apiUrl}/series/${id}?admins=${true}`, token, (result) => {
      const { startDate, endDate, videoUploadDateTime, ...others } = result;
      if (startDate && endDate) {
        form.setFieldsValue({
          ...others,
          startDate: moment(startDate),
          endDate: moment(endDate),
        });
      } else if (startDate) {
        form.setFieldsValue({ ...others, startDate: moment(startDate) });
      } else if (videoUploadDateTime) {
        form.setFieldsValue({
          ...others,
          videoUploadDateTime: moment(videoUploadDateTime),
        });
      } else {
        form.setFieldsValue(others);
      }
      setSeriesId(id);
      setMonetizationModel(result.model);
    });
  };

  const confirmDelete = (id) => {
    confirm({
      title: "Are you sure you want to delete this series?",
      icon: <ExclamationCircleOutlined />,
      okText: "Yes",
      okType: "danger",
      cancelText: "No",
      onOk() {
        Delete(
          `${apiUrl}/series/${id}`,
          token,
          (result) => {
            if (result.success) {
              notification.success({
                message: "Succes",
                description: "Series deleted successfully",
              });
              setCounter(counter + 1);
            } else {
              const playlists = result.detail.playlists;
              const promos = result.detail.promos;

              if (playlists.length > 0 && promos.length > 0) {
                notification.error({
                  message: "Error deleting series",
                  description: `Please delete from playlists [${playlists.join(
                    ", "
                  )}] and promos [${promos.join(", ")}] first`,
                });
              } else if (playlists.length > 0) {
                notification.error({
                  message: "Error deleting series",
                  description: `Please delete from playlists [${playlists.join(
                    ", "
                  )}] first`,
                });
              } else if (promos.length > 0) {
                notification.error({
                  message: "Error deleting series",
                  description: `Please delete from promos [${promos.join(
                    ", "
                  )}] first`,
                });
              }
            }
          },
          (err) => {
            notification.error({
              message: "Error deleting series",
              description: "Please check console for more details",
            });
          }
        );
      },
    });
  };

  const handleChange = (e, type) => {
    const file = e.target.files[0];

    setTrailerUploading(true);

    const upload = new tus.Upload(file, {
      endpoint: `${transcodeUrl}/files`,
      retryDelays: [0, 3000, 5000, 10000, 20000],
      metadata: {
        filename: file.name,
        filetype: file.type,
      },
      onError: (error) => {
        console.log("Failed because: " + error);
      },
      onProgress: (bytesUploaded, bytesTotal) => {
        var percentage = ((bytesUploaded / bytesTotal) * 100).toFixed(2);
        setTrailerUploadProgress(percentage);
        console.log(bytesUploaded, bytesTotal, percentage + "%");
      },
      onSuccess: () => {
        if (type === "trailer") {
          Post(
            `${transcodeUrl}/submitJob`,
            token,
            {
              id: seriesId,
              loc: upload.url.split("/").slice(-1)[0],
              media_type: "series_trailer",
            },
            (result) => {
              console.log(result);
            },
            () => {
              console.error(`Error transcoding content`);
            }
          );
        }

        console.log("Download %s from %s", upload.file.name, upload.url);
      },
    });

    upload.start();
  };

  const handleSearchChange = (seriesId) => {
    Fetch(`${apiUrl}/series/${seriesId}`, token, (result) => {
      const series = convertIdToKey([result]);
      series.forEach((s) => {
        if (typeof s["genre"] === "string") {
          s["genre"] = [s["genre"]];
        }

        if (!s.videoUploadDateTime) {
          s.videoUploadDateTime = "NA";
        } else {
          s.videoUploadDateTime = moment(s.videoUploadDateTime).format(
            "DD/MM/YYYY hh:mm A"
          );
        }
      });
      setSeries(series);
    });
  };

  return (
    <div className="manage-episodes-wrapper">
      <Select
        placeholder="Search Series"
        showSearch
        allowClear
        onChange={handleSearchChange}
        onClear={() => setSeries(seriesMaster)}
        optionFilterProp="children"
        filterOption={(input, option) =>
          option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
        }
        filterSort={(optionA, optionB) =>
          optionA.children
            .toLowerCase()
            .localeCompare(optionB.children.toLowerCase())
        }
      >
        {seriesMaster.map((s) => (
          <Option value={s.key}>{s.title}</Option>
        ))}
      </Select>
      <Table dataSource={series}>
        <Column title="Name" dataIndex="title" key="title" align="center" />
        <Column
          title="Language"
          dataIndex="language"
          key="language"
          align="center"
          render={(languages) => (
            <>
              {languages.map((l) => (
                <Tag color="blue" key={l}>
                  {l}
                </Tag>
              ))}
            </>
          )}
        />
        <Column
          title="Genres"
          dataIndex="genre"
          key="genre"
          align="center"
          render={(genre) => (
            <>
              {genre.map((g) => (
                <Tag color="blue" key={g}>
                  {g}
                </Tag>
              ))}
            </>
          )}
        />
        <Column
          title="Availability"
          dataIndex="availability"
          key="availability"
          align="center"
        />
        <Column title="Model" dataIndex="model" key="model" align="center" />
        <Column
          title="Trailer Exists"
          key="trailer"
          align="center"
          render={(_, record) =>
            record.trailer ? <CheckCircleFilled /> : <CloseCircleOutlined />
          }
        />
        <Column
          title="Video Uploaded Date"
          dataIndex="videoUploadDateTime"
          key="videoUploadDateTime"
          align="center"
        />
        <Column
          title="Actions"
          key="action"
          align="center"
          render={(text, record) => {
            if (record.availability === "unpublished") {
              return (
                <Space size="middle">
                  {record.comments ? (
                    <Popover
                      content={record.comments}
                      title="Admin Comments"
                      trigger="click"
                    >
                      <Button>View Comments<CommentOutlined /></Button>
                    </Popover>
                  ) : null}
                  <Button
                    onClick={() => showEditModal(record.key)}
                  >
                    Edit
                    <EditOutlined />
                  </Button>
                  <Button
                    onClick={() => {
                      confirmDelete(record.key);
                    }}
                  >
                    Delete
                    <DeleteOutlined />
                  </Button>
                </Space>
              );
            } else {
              return "-";
            }
          }}
        />
      </Table>
      <PlusCircleTwoTone
        twoToneColor="#FF0091"
        style={{ fontSize: "48px" }}
        className="add-series-btn"
        onClick={() => {
          setModalVisible(true);
          setModalType("Add");
        }}
      />
      <Modal
        title={modalType + " Series"}
        visible={modalVisible}
        onOk={handleSubmit}
        okText="Submit"
        onCancel={() => {
          setModalVisible(false);
          form.resetFields();
        }}
        confirmLoading={confirmLoading}
        destroyOnClose={true}
        maskClosable={false}
      >
        <Form
          form={form}
          layout="vertical"
          initialValues={{ availability: "perpetual" }}
        >
          <Form.Item name="title" label="Title" rules={[{ required: true }]}>
            <Input />
          </Form.Item>
          <Form.Item
            name="slug"
            label="Slug in Url"
            rules={[
              { required: true },
              {
                pattern: new RegExp(/^[a-zA-Z0-9\-]+$/),
                message: "Please enter alphanumeric or - only",
              },
            ]}
          >
            <Input
              size="middle"
              placeholder="Enter only alphanumeric or -"
            />
          </Form.Item>
          <Form.Item
            name="description"
            label="Description"
            rules={[{ required: true }]}
          >
            <TextArea />
          </Form.Item>
          <Form.Item
            name="language"
            label="Language"
            rules={[{ required: true }]}
          >
            <Select mode="multiple" placeholder="Choose a language" showSearch>
              {languages.map((language) => (
                <Option value={language.name}>{language.name}</Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item
            name="metaTitle"
            label="metaTitle"
            rules={[{ required: true }]}
          >
            <TextArea />
          </Form.Item>
          <Form.Item
            name="metaDescription"
            label="metaDescription"
            rules={[{ required: true }]}
          >
            <TextArea />
          </Form.Item>
          <Form.Item
            name="metaKeywords"
            label="metaKeywords"
            rules={[{ required: true }]}
          >
            <Select
              mode="tags"
              style={{
                width: "100%",
              }}
              tokenSeparators={["Enter"]}
            ></Select>
          </Form.Item>
          <Form.Item
            name="genre"
            label="Genre / Category"
            rules={[{ required: true }]}
          >
            <Select placeholder="Choose a genre" mode="multiple" showSearch>
              {genres.map((genre) => (
                <Option value={genre.name}>{genre.name}</Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item
            name="maturity"
            label="Maturity"
            rules={[{ required: true }]}
          >
            <Select placeholder="Choose maturity">
              <Option value="U">U (Universal)</Option>
              <Option value="U/A 7+">U/A 7+ (For Ages 7 and Above)</Option>
              <Option value="U/A 13+">U/A 13+ (For Ages 13 and Above)</Option>
              <Option value="U/A 16+">U/A 16+ (For Ages 16 and Above)</Option>
              <Option value="A">A (Adults - Ages 18 and Above)</Option>
            </Select>
          </Form.Item>
          <Form.Item name="actors" label="Actors" rules={[{ required: true }]}>
            <Select showSearch mode="multiple" className="form-card-selector">
              {actors.map((actor) => (
                <Option key={actor.key} value={actor.name}>
                  {actor.name}
                </Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item
            name="directors"
            label="Directors"
            rules={[{ required: true }]}
          >
            <Select showSearch mode="multiple" className="form-card-selector">
              {directors.map((director) => (
                <Option key={director.key} value={director.name}>
                  {director.name}
                </Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item
            name="producers"
            label="Producers"
            rules={[{ required: true }]}
          >
            <Select showSearch mode="multiple">
              {producers.map((producer) => (
                <Option key={producer.key} value={producer.name}>
                  {producer.name}
                </Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item
            name="availability"
            label="Availability"
            rules={[{ required: true }]}
          >
            <Radio.Group onChange={(e) => setAvailability(e.target.value)}>
              <Radio value="perpetual">Perpetual</Radio>
              <Radio value="restricted">Restricted</Radio>
              <Radio value="unpublished">Unpublished</Radio>
            </Radio.Group>
          </Form.Item>
          {availability === "restricted" && (
            <>
              <Form.Item
                name="startDate"
                label="Start Date"
                className="form-item-selector"
                rules={[{ required: true }]}
              >
                <DatePicker format="YYYY-MM-DD HH:mm:ss" showTime />
              </Form.Item>
              <Form.Item
                name="endDate"
                label="End Date"
                className="form-item-selector"
                format="YYYY-MM-DD HH:mm:ss"
              >
                <DatePicker format="YYYY-MM-DD HH:mm:ss" showTime />
              </Form.Item>
            </>
          )}
          <Form.Item label="Model" name="model" rules={[{ required: true }]}>
            <Radio.Group onChange={(e) => setMonetizationModel(e.target.value)}>
              <Radio value="ticket">Ticket</Radio>
              <Radio value="subscription">Subscription</Radio>
              <Radio value="free">Free</Radio>
            </Radio.Group>
          </Form.Item>
          {monetizationModel === "ticket" && (
            <>
              <Form.Item
                name="streamPeriod"
                label="Streaming Period"
                className="form-item-selector"
                rules={[{ required: true }]}
              >
                <InputNumber
                  min={1}
                  max={30}
                  placeholder="Enter number of days (1-30)"
                />
              </Form.Item>
              <Form.Item
                name="subscriberPrice"
                label="Price for Subscribers"
                className="form-item-selector"
                rules={[{ required: true }]}
              >
                <MultiCurrencyInput
                  form={form}
                  fieldName={"subscriberPrice"}
                ></MultiCurrencyInput>
              </Form.Item>
              <Form.Item
                name="nonSubscriberPrice"
                label="Price for Non Subscribers"
                className="form-item-selector"
                rules={[{ required: true }]}
              >
                <MultiCurrencyInput
                  form={form}
                  fieldName={"nonSubscriberPrice"}
                ></MultiCurrencyInput>
              </Form.Item>
            </>
          )}
          <Form.Item
            name="cardImage"
            label="Card Image"
            rules={[{ required: true }]}
          >
            <UploadBanners
              name={"cardImageSeries"}
              type="series"
              updateForm={form}
            />
          </Form.Item>
          <Form.Item
            name="detailImage"
            label="Detail Image"
            rules={[{ required: true }]}
          >
            <UploadBanners
              name={"detailImage"}
              type="series"
              updateForm={form}
            />
          </Form.Item>
          <Form.Item
            name="trailerImage"
            label="Trailer Image"
            rules={[{ required: true }]}
          >
            <UploadBanners
              name={"trailerImage"}
              type="series"
              updateForm={form}
            />
          </Form.Item>
        </Form>
      </Modal>
    </div>
  );
};

export default ManageSeries;
