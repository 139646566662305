import { Space, Tabs, Radio, Select, Tag, Input, Card } from "antd";
import { useContext, useEffect, useState } from "react";

import { Context } from "../../context";
import { Fetch, Post } from "../../fetch";
import { convertIdToKey } from "../../utils";
import UploadSubtitles from "./subtitles";
import UploadTrailer from "./trailers";
import UploadVideo from "./videos";

const { TabPane } = Tabs;
const { TextArea } = Input;
const { Option } = Select;

const UploadContent = () => {
  const [contentType, setContentType] = useState("movie");
  const [movies, setMovies] = useState([]);
  const [episodes, setEpisodes] = useState([]);
  const [series, setSeries] = useState([]);
  const [songs, setSongs] = useState([]);
  const [activeObj, setActiveObj] = useState({});
  const [upload, setUpload] = useState({
    id: null,
    season: null,
    episode: null,
    type: "",
  });

  const initUpload = {
    id: null,
    season: null,
    episode: null,
    type: "",
  };

  const { token, apiUrl, superAdmin } = useContext(Context);

  useEffect(() => {
    Fetch(`${apiUrl}/movies`, token, (result) => {
      const movs = convertIdToKey(result.movies);
      setMovies(movs);
    });

    Fetch(`${apiUrl}/series`, token, (result) => {
      const series = convertIdToKey(result.series);
      setSeries(series);
    });

    Fetch(`${apiUrl}/songs`, token, (result) => {
      const songs = convertIdToKey(result.songs);
      setSongs(songs);
    });
  }, [apiUrl, token]);

  useEffect(() => {
    if (
      upload.type === "episode" &&
      upload._id &&
      upload._id.includes("_") === false
    ) {
      Fetch(`${apiUrl}/series/${upload._id}/episodes`, token, (result) => {
        setEpisodes(result.episodes);
      });
    }
  }, [upload]);

  useEffect(() => {
    setActiveObj({});
    setUpload(initUpload);
  }, [contentType]);

  return (
    <div className="add-content-wrapper">
      <h3>Upload Content</h3>
      <Space direction="vertical">
        <Radio.Group
          className="content-type-option"
          value={contentType}
          onChange={(e) => setContentType(e.target.value)}
        >
          <Radio value="movie">Movie</Radio>
          <Radio value="series">Series</Radio>
          <Radio value="episode">Episode</Radio>
          <Radio value="song">Song</Radio>
        </Radio.Group>
        {contentType === "movie" && (
          <>
            <Select
              style={{ width: 300 }}
              placeholder={"Select Movie"}
              allowClear
              showSearch
              optionFilterProp="children"
              onClear={() => {
                setActiveObj({});
                setUpload(initUpload);
              }}
              onChange={(val) => {
                setUpload({
                  ...upload,
                  _id: val,
                  type: "movies",
                  episode: null,
                  season: null,
                });
                setActiveObj(movies.find((m) => m.key === val));
              }}
            >
              {movies.map((content) => (
                <Select.Option key={content.key} value={content.key}>
                  {content.title}
                </Select.Option>
              ))}
            </Select>
          </>
        )}
        {contentType === "series" && (
          <>
            <Select
              style={{ width: 300 }}
              placeholder={"Select Series"}
              allowClear
              showSearch
              optionFilterProp="children"
              onClear={() => {
                setActiveObj({});
                setUpload(initUpload);
              }}
              onChange={(val) => {
                setUpload({
                  ...upload,
                  _id: val,
                  type: "series",
                  episode: null,
                  season: null,
                });
                setActiveObj(series.find((m) => m.key === val));
              }}
            >
              {series.map((content) => (
                <Select.Option key={content.key} value={content.key}>
                  {content.title}
                </Select.Option>
              ))}
            </Select>
          </>
        )}
        {contentType === "episode" && (
          <Space direction="horizontal">
            <Select
              style={{ width: 300 }}
              placeholder={"Select Series"}
              allowClear
              showSearch
              optionFilterProp="children"
              onClear={() => {
                setActiveObj({});
                setUpload(initUpload);
              }}
              onChange={(val) =>
                setUpload({
                  ...upload,
                  _id: val,
                  key: val,
                  type: "episode",
                  season: null,
                  episode: null,
                })
              }
            >
              {series.map((content) => (
                <Select.Option key={content.key} value={content.key}>
                  {content.title}
                </Select.Option>
              ))}
            </Select>
            <Select
              style={{ width: 300 }}
              placeholder={"Select Episode"}
              allowClear
              showSearch
              optionFilterProp="children"
              onClear={() => {
                setActiveObj({});
                setUpload(initUpload);
              }}
              onChange={(v, _) => {
                setUpload({
                  ...upload,
                  season: v.split("_")[0],
                  episode: v.split("_")[1],
                  type: "episode",
                  _id: `${upload.key}_${v}`,
                });
                setActiveObj(
                  episodes.find(
                    (ep) =>
                      v.split("_")[0] == ep.season &&
                      ep.number == v.split("_")[1]
                  )
                );
              }}
            >
              {episodes.map((e) => (
                <Select.Option
                  key={`${e.season}_${e.number}`}
                  value={`${e.season}_${e.number}`}
                >
                  {e.name}
                </Select.Option>
              ))}
            </Select>
          </Space>
        )}
        {contentType === "song" && (
          <>
            <Select
              style={{ width: 300 }}
              placeholder={"Select Song"}
              allowClear
              showSearch
              optionFilterProp="children"
              onClear={() => setActiveObj({})}
              onChange={(val) => {
                setUpload({
                  ...upload,
                  _id: val,
                  type: "songs",
                  episode: null,
                  season: null,
                });
                setActiveObj(songs.find((m) => m.key === val));
              }}
            >
              {songs.map((content) => (
                <Select.Option key={content.key} value={content.key}>
                  {content.title}
                </Select.Option>
              ))}
            </Select>
          </>
        )}

        {/* Videos module */}
        {(["movie", "song"].includes(contentType) && upload._id) ||
          (contentType === "episode" && "_id" in upload && upload.episode) ? (
          <UploadVideo
            id={
              activeObj && "_id" in activeObj
                ? activeObj._id
                : `contentupload-${contentType}`
            }
            upload={{ ...upload }}
            activeObj={activeObj ? activeObj : {}}
          />
        ) : null}

        {/* Trailers module */}
        {["movie", "series"].includes(contentType) && upload._id ? (
          <UploadTrailer
            id={
              activeObj && "_id" in activeObj
                ? activeObj._id
                : `trailerupload-${contentType}`
            }
            upload={{
              ...upload,
              type:
                upload.type === "movies" ? "movie_trailer" : "series_trailer",
            }}
            activeObj={activeObj ? activeObj : {}}
          />
        ) : null}

        {/* Subtitles module */}
        {(["movie", "song"].includes(contentType) && upload._id) ||
          (contentType === "episode" && "_id" in upload && upload.episode) ? (
          <Card title={"Subtitles (only SRT)"}>
            <UploadSubtitles
              id={
                activeObj && "_id" in activeObj
                  ? activeObj._id
                  : `Srt-${contentType}`
              }
              upload={{
                ...upload,
                _id: upload._id,
                type: contentType,
              }}
              subtitles={
                activeObj && "subtitles" in activeObj ? activeObj.subtitles : {}
              }
            />
          </Card>
        ) : null}
      </Space>
    </div>
  );
};

export default UploadContent;
